const actionTypes = Object.freeze({
    //app
    APP_START_UP_COMPLETE: 'APP_START_UP_COMPLETE',
    SET_CONTENT_OF_CONFIRM_MODAL: 'SET_CONTENT_OF_CONFIRM_MODAL',
    // change language
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',

    //user
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAIL: 'USER_LOGIN_FAIL',
    PROCESS_LOGOUT: 'PROCESS_LOGOUT',

    //admin
    ADMIN_ACTION_START: 'ADMIN_ACTION_START',
    ADMIN_ACTION_SUCCESS: 'ADMIN_ACTION_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',
    //admin ROLE
    ADMIN_ROLE_START: 'ADDMIN_ROLE_START',
    ADMIN_ROLE_SUCCESS: 'ADMIN_ROLE_SUCCESS',
    ADMIN_ROLE_FAILURE: 'ADMIN_ROLE_FAILURE',
    //admin POSITION
    ADMIN_POSITION_START: 'ADMIN_POSITION_START',
    ADMIN_POSITION_SUCCESS: 'ADMIN_POSITION_SUCCESS',
    ADMIN_POSITION_FAILURE: 'ADMIN_POSITION_FAILURE',
    //admin CREATE
    ADMIN_CREATE_START: 'ADMIN_CREATE_START',
    ADMIN_CREATE_SUCCESS: 'ADMIN_CREATE_SUCCESS',
    ADMIN_CREATE_FAILURE: 'ADMIN_CREATE_FAILURE',
    // admin get data user
    ADMIN_FETCH_GET_DATA_SUCCESS: 'ADMIN_FETCH_GET_DATA_SUCCESS',
    ADMIN_FETCH_GET_DATA_FAILURE: 'ADMIN_FETCH_GET_DATA_FAILURE',
    // admin get data doctor with limit
    FETCH_DOCTOR_LIMIT_SUCCESS: 'FETCH_DOCTOR_LIMIT_SUCCESS',
    FETCH_DOCTOR_LIMIT_FAILURE: 'FETCH_DOCTOR_LIMIT_FAILURE',
    // get data consultant with limit
    FETCH_REMOTE_CONSULTANT_LIMIT_SUCCESS: 'FETCH_REMOTE_CONSULTANT_LIMIT_SUCCESS',
    FETCH_REMOTE_CONSULTANT_LIMIT_FAILURE: 'FETCH_REMOTE_CONSULTANT_LIMIT_FAILURE',
    // admin get data all doctors
    GET_DATA_ALL_DOCTORS_SUCCESS: 'GET_DATA_ALL_DOCTORS_SUCCESS',
    GET_DATA_ALL_DOCTORS_FAILURE: 'GET_DATA_ALL_DOCTORS_FAILURE',
    // adim create data dotor
    CREATE_DATA_DOCTORS_SUCCESS: 'CREATE_DATA_DOCTORS_SUCCESS',
    CREATE_DATA_DOCTORS_FAILURE: 'CREATE_DATA_DOCTORS_FAILURE',
    // doctor doctor detail
    DOCTOR_INFO_SUCCESS: 'DOCTOR_INFO_SUCCESS',
    DOCTOR_INFO_FAILURE: 'DOCTOR_INFO_FAILURE',
    // time appointment
    DOCTOR_TIME_SUCCESS: 'DOCTOR_TIME_SUCCESS',
    DOCTOR_TIME_FAILURE: 'DOCTOR_TIME_FAILURE',
    //medical specialty
    GET_DATA_ALL_SPECIALTY_SUCCESS: 'GET_DATA_ALL_SPECIALTY_SUCCESS',
    GET_DATA_ALL_SPECIALTY_FAILURE: 'GET_DATA_ALL_SPECIALTY_FAILURE',
    //login patient 
    PATIENT_LOGIN_SUCCESS: 'PATIENT_LOGIN_SUCCESS',
    PATIENT_LOGIN_FAIL: 'PATIENT_LOGIN_FAIL',
    PROCESS_LOGOUT_PATIENT: 'PROCESS_LOGOUT_PATIENT',
    // type consultant
    GET_DATA_ALL_CONSULTANT_SUCCESS: 'GET_DATA_ALL_CONSULTANT_SUCCESS',
    GET_DATA_ALL_CONSULTANT_FAILURE: 'GET_DATA_ALL_CONSULTANT_FAILURE',
    // all data user consultant
    GET_ALL_USER_CONSULTANT_SUCCESS: 'GET_ALL_USER_CONSULTANT_SUCCESS',
    GET_ALL_USER_CONSULTANT_FAILURE: 'GET_ALL_USER_CONSULTANT_FAILURE',

})

export default actionTypes;